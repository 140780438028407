import axios from '@/plugins/axios';
import type {AxiosResponse} from 'axios';

const resource = 'api/traceability'

export default {
    postTraceabilityAuthenticate(gameModeId: number, isLaunch: boolean, isFinish: boolean): Promise<AxiosResponse<void>> {
        return axios.post(`${resource}/authenticate`, {
            game_mode_id: gameModeId,
            is_launch: isLaunch,
            is_finish: isFinish
        });
    },
}
