<template>
    <div id="game-close-as-possible-view" class="h-100">
        <v-row class="h-100 ma-0" justify="center" align="center">

            <v-col v-if="loading_questions" class="d-flex justify-center">
                <v-card elevation="4" :class="this.is_mobile ? 'w-100' : 'width-500'"
                        class="d-flex flex-column justify-center height-400"
                >
                    <div>
                        <v-card-text class="text-center">
                            <v-progress-circular indeterminate :size="100" :width="6" color="primary"/>
                        </v-card-text>
                    </div>
                </v-card>
            </v-col>

            <v-col v-else-if="!loading_questions && current_question" class="d-flex justify-center">
                <v-card elevation="4" :class="this.is_mobile ? 'w-100' : 'width-500'">
                    <v-card-title class="d-flex justify-center">
                        <v-chip :prepend-icon="current_question.theme.icon" :color="current_question.theme.color"
                                class="ma-2" size="large" label variant="elevated"
                        >
                            {{ current_question.theme.label }}
                        </v-chip>
                    </v-card-title>

                    <v-card-text class="text-center align-center mt-4 text-h5 font-weight-bold line-height-normal">
                        {{ current_question.label }}
                    </v-card-text>

                    <v-card-text class="text-center my-4 font-weight-bold line-height-normal">
                        <span v-if="show_answer">{{ current_question.propositions[0].label }}</span>
                        <span v-else style="filter: blur(5px)">
                            {{ $t('play_game_as_close_as_possible.its_an_answer') }}
                        </span>
                    </v-card-text>

                    <v-card-text class="mx-3 mb-2">
                        <v-row>
                            <v-btn @click="!show_answer ? show_answer = true : nextQuestion()" block variant="flat"
                                   size="large" color="primary"
                            >
                                <span v-if="!show_answer">{{ $t('play_game_as_close_as_possible.see_answer') }}</span>
                                <span v-else>{{ $t('play_game_as_close_as_possible.next_question') }}</span>
                            </v-btn>

                            <dialog-explications-component :disabled="current_question.explication" class="w-100 mt-4"
                                                           :explication="current_question.explication"
                            />

                            <v-btn @click="this.$router.push({name: 'dashboard'})" block color="primary"
                                   variant="outlined" class="mt-4"
                            >
                                {{ $t('play_game_as_close_as_possible.return') }}
                            </v-btn>
                        </v-row>

                        <v-row class="pt-4" justify="end">
                            <dialog-report-component :game_mode_id="game_mode.id" :question_id="current_question.id"
                                                     type="signal_question"
                            />
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col v-else-if="is_end" class="d-flex justify-center">
                <v-card elevation="4" :class="this.is_mobile ? 'w-100' : 'width-500'">
                    <v-card-title class="mx-2 mt-4 text-h5 pb-0">
                        {{ $t('play_game_as_close_as_possible.game_end_thanks') }}
                    </v-card-title>

                    <v-card-text>
                        <v-btn @click="relaunchGame()" block color="primary" variant="flat" size="large" class="mt-4">
                            {{ $t('play_game_as_close_as_possible.relaunch_game') }}
                        </v-btn>

                        <v-btn @click="this.$router.push({name: 'dashboard'})" block color="primary"
                               variant="outlined" class="mt-4"
                        >
                            {{ $t('play_game_as_close_as_possible.return') }}
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DialogExplicationsComponent from "../../../components/game/DialogExplicationsComponent";
import DialogReportComponent from "../../../components/global/DialogReportComponent";
import QuestionRepository from "../../../services/QuestionRepository";

export default {
    name: "PlayGameAsCloseAsPossibleView",
    components: {DialogExplicationsComponent, DialogReportComponent},
    data() {
        return {
            loading_questions: false,
            questions: [],
            index_current_question: 0,
            current_question: {},
            show_answer: false,
            game_mode: this.$store.getters['game_mode/game_mode'],
            is_end: false
        }
    },
    created() {
        this.getGameAsCloseAsPossibleQuestions();
    },
    methods: {
        getGameAsCloseAsPossibleQuestions() {
            this.loading_questions = true;

            QuestionRepository.getQuestionsWithAnswerByGameModeId(this.game_mode.id, 10).then(response => {
                this.questions = response.data;
                this.current_question = this.questions[0];
            }).catch(error => {
                this.manageError(error, this.$t('play_game_as_close_as_possible.error_encountered_get_questions'));
            }).finally(() => {
                this.loading_questions = false;
            });
        },

        nextQuestion() {
            this.increaseTotalSeenOfQuestionRead(this.current_question.id);
            this.show_answer = false;
            this.index_current_question++;
            this.current_question = this.questions[this.index_current_question];

            if (this.index_current_question === this.questions.length) {
                this.is_end = true;
            }
        },

        async increaseTotalSeenOfQuestionRead(idQuestion) {
            await QuestionRepository.patchIncreaseTotalSeen([idQuestion]);
        },

        relaunchGame() {
            this.addTraceabilityAuthenticate(false, true);
            this.loading_questions = true;
            this.index_current_question = 0;
            this.show_answer = false;
            this.is_end = false;
            this.getGameAsCloseAsPossibleQuestions();
        },
    },
    mounted() {
       this.addTraceabilityAuthenticate(true, false);
    }
}
</script>
