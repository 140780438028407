<template>
    <div id="dashboard-view" class="h-100">
        <v-row class="h-100 ma-0" justify="center" align="center">
            <v-col class="d-flex justify-center">
                <v-card elevation="4" :class="this.is_mobile ? 'w-100' : 'width-500'">
                    <v-card-actions class="mx-4 my-4">
                        <v-row>
                            <v-col cols="12">
                                <v-row>
                                    <v-col>
                                        <v-tooltip class="text-center" location="bottom">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props" @click="this.$router.push({name: 'profile'});"
                                                       variant="outlined" icon="mdi-account-circle" color="primary"
                                                       size="large"
                                                />
                                            </template>

                                            {{ $t('dashboard_view.profile') }}
                                        </v-tooltip>
                                    </v-col>

                                    <v-col align="end">
                                        <v-tooltip class="text-center" location="bottom">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props" @click="this.$auth.logout({})"
                                                       variant="outlined" icon="mdi-logout" color="primary" size="large"
                                                />
                                            </template>

                                            {{ $t('dashboard_view.logout') }}
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="12">
                                <p>Salut {{ $store.getters['user_connected/user_connected'].first_name }} ! On fait quoi ?</p>
                            </v-col>

                            <v-col v-if="this.displayElement([0, 50, 500])"
                                   cols="12"
                            >
                                <v-btn @click="this.$router.push({name: 'game_mode'});" block variant="flat"
                                       class="font-weight-bold" size="large" color="primary"
                                >
                                    {{ $t('dashboard_view.launch_game') }}
                                </v-btn>
                            </v-col>

                            <v-col cols="12">
                                <v-btn @click="this.$router.push({name: 'join_game_quizz'});" block variant="flat"
                                       class="font-weight-bold" size="large" color="primary"
                                >
                                    {{ $t('dashboard_view.join_game') }}
                                </v-btn>
                            </v-col>

                            <v-col cols="12" class="pt-4">
                                <v-row>
                                    <div class="pointer pl-2" @click="openTwitter()">
                                        <v-icon color="primary">mdi-twitter</v-icon>
                                        <span class="text-decoration-underline pl-1">Twitter</span>
                                    </div>

                                    <div class="pointer pl-4" @click="openTipee()">
                                        <v-icon color="primary">mdi-alpha-t-box-outline</v-icon>
                                        <span class="text-decoration-underline pl-1">Tipee</span>
                                    </div>
                                </v-row>
                            </v-col>

                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import GameModeRepository from "../services/GameModeRepository";

export default {
    name: "DashboardView",
    computed() {
        
    },
    created() {
        if(this.displayElement([0, 50, 500])) {
            this.getOnlineGameModes();
        }
    },
    methods: {
        getOnlineGameModes() {
            GameModeRepository.getOnlineGameModes().then(response => {
                this.$store.dispatch('game_mode/setGameModes', response.data);
            }).catch(error => {
                this.manageError(error, this.$t('dashboard_view.error_encountered_get_game_modes'));
            });
        },

        openTwitter() {
            window.open('https://x.com/contact_popoche', '_blank');
        },

        openTipee() {
            window.open('https://fr.tipeee.com/popoche', '_blank');
        }
    }
}
</script>
