<template>
    <div id="result-game-quizz-view" class="h-100">
        <v-row v-if="game_result" class="h-100 ma-0" justify="center" align="center">

            <v-row class="mx-4 d-flex justify-center bg-white">
                <template v-if="!display_list_result">
                    <v-col md="4" cols="12" class="pa-0">
                        <v-card elevation="0" align="center">
                            <v-card-title class="d-flex justify-center">
                                <v-chip :prepend-icon="actual_question.theme.icon" :color="actual_question.theme.color"
                                        class="ma-2" size="large" label variant="elevated"
                                >
                                    {{ actual_question.theme.label }}
                                </v-chip>
                            </v-card-title>

                            <v-card-text class="text-center text-h6 font-weight-bold line-height-normal">
                                {{ actual_question.label }}
                            </v-card-text>

                            <v-card-text class="mx-4">
                                <v-row>
                                    <v-btn v-for="proposition in actual_question.propositions" :key="proposition.id"
                                           :class="proposition.is_answer ? 'bg-green-lighten-1 border-0' : ''"
                                           block variant="outlined" class="font-weight-bold mb-4" color="primary"
                                    >
                                        <span :class="proposition.is_answer ? 'text-white' : ''">
                                            {{ proposition.label }}
                                        </span>
                                    </v-btn>
                                </v-row>
                            </v-card-text>

                            <Pie :data="chartData" class="mb-2 chart-pie-result"/>

                            <v-card-text class="pb-2">
                                <v-row>
                                    <v-col cols="4">
                                        <v-icon class="mr-1" color="#757575" size="small">mdi-square</v-icon>
                                        <span class="text-caption">{{ $t('result_game_quizz_view.no_answer') }}</span>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-icon class="mr-1" color="#E46651" size="small">mdi-square</v-icon>
                                        <span class="text-caption">{{ $t('result_game_quizz_view.bad_answer') }}</span>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-icon class="mr-1" color="#41B883" size="small">mdi-square</v-icon>
                                        <span class="text-caption">{{ $t('result_game_quizz_view.good_answer') }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col md="8" cols="12" class="px-4">
                        <v-row class="justify-end">
                            <v-col :cols="this.is_mobile ? 12 : 4">
                                <dialog-explications-component :disabled="actual_question.explication"
                                                               :explication="actual_question.explication"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col v-for="user in game_result.users" lg="3" md="4" sm="4" xs="6" cols="12"
                                   :key="user.id"
                            >
                                <v-card :class="getAnswerColorOfUser(getAnswerOfUser(user.answers))">
                                    <v-card-text class="text-center">
                                        <p class="font-weight-bold">
                                            {{ user.pseudo ? user.pseudo : user.first_name + ' ' + user.last_name }}
                                        </p>
                                        <p>
                                            {{
                                                getAnswerOfUser(user.answers) ? getAnswerOfUser(user.answers).label : $t('result_game_quizz_view.no_answer')
                                            }}
                                        </p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row align="end" v-if="parseInt(game_result.total_questions) === index_question + 1">
                            <v-col cols="12">
                                <v-btn @click="display_list_result = true; " block color="primary">
                                    {{ $t('result_game_quizz_view.see_results') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" align="center">
                        <hr class="mx-2 bg-primary border-0 height-1"/>
                        <v-pagination @next="nextQuestion()" @prev="previousQuestion()"
                                      @click="pickQuestion()" v-model="page"
                                      :length="game_result.total_questions"
                                      :total-visible="this.is_mobile ? 3 : 5" color="primary" class="pt-2"
                        />
                    </v-col>
                </template>

                <template v-else>
                    <v-col class="pl-4" cols="12">
                        <p class="text-h5">{{ $t('result_game_quizz_view.your_notes') }}</p>
                    </v-col>

                    <v-col v-for="user in game_result.users" class="px-4" md="4" sm="6" cols="12" :key="user.id">
                        <v-card>
                            <v-card-text :class="getFinalResultColor(user.good_answers)">
                                <p>
                                    {{ user.first_name + ' ' + user.last_name }} : {{ user.good_answers }} /
                                    {{ game_result.total_questions }}
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" align="center">
                        <hr class="mx-2 bg-primary border-0 height-1"/>
                        <v-btn class="mt-4" color="primary" @click="display_list_result = false">
                            {{ $t('result_game_quizz_view.see_questions') }}
                        </v-btn>
                    </v-col>
                </template>
            </v-row>
        </v-row>
    </div>
</template>

<script>
import DialogExplicationsComponent from "../../../components/game/DialogExplicationsComponent";
import GameRepository from "../../../services/GameRepository";
import {Pie} from 'vue-chartjs'

export default {
    name: "ResultGameQuizzView",
    components: {DialogExplicationsComponent, Pie},
    data() {
        return {
            loading_result_game: false,
            id_game: this.$store.getters['game/game'].id,
            game_result: null,
            actual_question: null,
            index_question: 0,
            page: 1,
            display_list_result: false,
        }
    },
    computed: {
        chartData() {
            return {
                datasets: [
                    {
                        backgroundColor: ['#E46651', '#41B883', '#757575'],
                        data: [this.actual_question.wrong_answers, this.actual_question.good_answers, this.actual_question.no_answers]
                    }
                ]
            };
        }
    },
    created() {
        this.getGameResults();
    },
    methods: {
        getGameResults() {
            this.loading_result_game = true;

            GameRepository.getGameResults(this.id_game).then(response => {
                this.game_result = response.data;
                this.actual_question = response.data.questions[this.index_question];
            }).catch(error => {
                this.manageError(error, this.$t('result_game_quizz_view.error_encountered_get_results_game'));
            }).finally(() => {
                this.loading_result_game = false;
            })
        },

        previousQuestion() {
            this.index_question--;
            this.actual_question = this.game_result.questions[this.index_question];
        },

        nextQuestion() {
            this.index_question++;
            this.actual_question = this.game_result.questions[this.index_question];
        },

        pickQuestion() {
            this.index_question = this.page - 1;
            this.actual_question = this.game_result.questions[this.index_question];
        },

        getAnswerOfUser(answers) {
            let userAnswer = answers.find(answer => {
                return answer.question_id === this.actual_question.id;
            });

            return this.actual_question.propositions.find(proposition => {
                return userAnswer ? proposition.id === userAnswer.proposition_id : null;
            });
        },

        getAnswerOfQuestion() {
            return this.actual_question.propositions.find(proposition => {
                return proposition.is_answer === 1;
            }).id;
        },

        getAnswerColorOfUser(userAnswer) {
            let color = 'bg-grey-darken-1';
            if (userAnswer) {
                color = (userAnswer.id === this.getAnswerOfQuestion() ? 'bg-green-lighten-1' : 'bg-red-lighten-1');
            }
            return color;
        },

        getFinalResultColor(totalGoodAnswers) {
            let note = totalGoodAnswers / this.game_result.total_questions;
            let color = 'bg-green-darken-3';

            if (note <= 0.18) {
                color = 'bg-red-darken-4';
            } else if (note <= 0.34) {
                color = 'bg-red-darken-2';
            } else if (note <= 0.50) {
                color = 'bg-orange-darken-3';
            } else if (note <= 0.66) {
                color = 'bg-orange-darken-1';
            } else if (note <= 0.84) {
                color = 'bg-green-lighten-1';
            } else if (note <= 1) {
                color = 'bg-green'
            }

            return color;}
    },
    mounted() {
        //Ici il faudra faire la gestion de la fin de la partie
        this.addTraceabilityAuthenticate(false, true);
    }
}
</script>

<style>
.height-1 {
    height: 1px !important;
}

.chart-pie-result {
    height: 200px !important;
    width: 200px !important;
}
</style>