const state = () => ({
    show_snackbar: false,
    snackbar_text: '',
    snackbar_type: '',
});

const mutations = {
    SHOW_SNACKBAR(state, item) {
        state.snackbar_text = item.text;
        state.snackbar_type = item.type;
        state.show_snackbar = true;
    },
    HIDE_SNACKBAR(state) {
        state.show_snackbar = false;
    },
};

const actions = {
    async showSnackbar({commit}, payload) {
        await commit('SHOW_SNACKBAR', {text: payload.snackbar_text, type: payload.snackbar_type});
    },
    async hideSnackbar({commit}) {
        await commit('HIDE_SNACKBAR');
    },
};

const getters = {
    show_snackbar: (state) => state.show_snackbar,
    snackbar_text: (state) => state.snackbar_text,
    snackbar_type: (state) => state.snackbar_type,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
