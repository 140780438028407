<template>
    <div id="avatar-component">
        <v-card elevation="0" class="py-2">
            <v-card-title>{{ $t('profile_view.my_avatar') }}</v-card-title>

            <v-card-text class="pb-2">
                <v-row class="display-flex-center">
                    <v-col cols="5" class="d-flex align-center justify-center">
                        <v-avatar :color="swatch_selected" size="128" class="text-h3" :class="text_color">
                            {{ initial }}
                        </v-avatar>
                    </v-col>
                    <v-col cols="7" align="center">
                        <v-color-picker v-model="swatch_selected" :swatches="swatches" elevation="0" :modes="['hex']"
                                        class="ma-0 max-width-none pb-4" width="O" hide-canvas hide-inputs show-swatches
                        />

                        <v-btn @click="updateInitialColor()" :loading="loading_avatar"
                               class="font-weight-bold mr-2" size="large" color="primary" variant="flat" block
                        >
                            {{ $t('profile_view.save') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import UserRepository from "../../services/UserRepository";

export default {
    name: "AvatarComponent",
    data() {
        return {
            loading_avatar: false,
            text_color: '',
            swatch_selected: null,
            swatches: [
                ['#90A4AE', '#263238', '#FF0000', '#AA0000', '#550000'],
                ['#FFFF00', '#FF6F00', '#E65100', '#AAAA00', '#555500'],
                ['#AED581', '#43A047', '#00FF00', '#00AA00', '#005500'],
                ['#80DEEA', '#00FFFF', '#03A9F4', '#00AAAA', '#005555'],
                ['#9C27B0', '#4A148C', '#0000FF', '#0000AA', '#000055'],
            ],
        }
    },
    watch: {
        swatch_selected() {
            let hex = (this.swatch_selected.charAt(0) === '#') ? this.swatch_selected.substring(1, 7) : this.swatch_selected;
            let r = parseInt(hex.slice(0, 2), 16);
            let g = parseInt(hex.slice(2, 4), 16);
            let b = parseInt(hex.slice(4, 6), 16);

            this.text_color = (((r * 0.299 + g * 0.587 + b * 0.114) > 186) ? 'text-black' : 'text-white');
        }
    },
    computed: {
        initial() {
            return this.$store.getters['user_connected/user_connected'].first_name.charAt(0).toUpperCase() +
                this.$store.getters['user_connected/user_connected'].last_name.charAt(0).toUpperCase()
        }
    },
    created() {
        this.swatch_selected = this.$store.getters['user_connected/user_connected'].initial_color;
    },
    methods: {
        updateInitialColor() {
            this.loading_avatar = true;

            UserRepository.putUpdateMyInitialBadgeColor(
                this.swatch_selected
            ).then(() => {
                this.$store.dispatch('user_connected/setInitialColorAfterUpdate', this.swatch_selected);
                this.showSnackbar('success', this.$t('profile_view.color_updated'));
            }).catch((error) => {
                this.manageError(error, this.$t('profile_view.error_encountered_update_avatar'));
            }).finally(() => {
                this.loading_avatar = false;
            });
        }
    }
}
</script>

<style>
.v-color-picker-preview__eye-dropper {
    visibility: hidden;
    width: 0;
}
</style>