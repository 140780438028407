<template>
    <div id="play-game-which-is-intruder" class="h-100">
        <v-row class="h-100 ma-0" justify="center" align="center">

            <v-col v-if="loading_questions" class="d-flex justify-center">
                <v-card elevation="4" :class="this.is_mobile ? 'w-100' : 'width-500'"
                        class="d-flex flex-column justify-center height-400"
                >
                    <div>
                        <v-card-text class="text-center">
                            <v-progress-circular indeterminate :size="100" :width="6" color="primary"/>
                        </v-card-text>
                    </div>
                </v-card>
            </v-col>

            <v-col v-else lg="8" md="10" sm="11" cols="12">
                <v-card class="pa-4">
                    <v-row>
                        <v-col md="6" cols="12" class="pa-0">
                            <v-card elevation="0">
                                <v-card-title class="text-center">
                                    <v-chip :prepend-icon="current_question.theme.icon" class="ma-2" size="large" label
                                            :color="current_question.theme.color" variant="elevated"
                                    >
                                        {{ current_question.theme.label }}
                                    </v-chip>
                                </v-card-title>

                                <v-card-text class="text-center text-h5 font-weight-bold line-height-normal">
                                    {{ current_question.label }}
                                </v-card-text>

                                <v-card-text class="mx-4 py-0">
                                    <v-btn v-for="proposition in current_question.propositions" :key="proposition.id"
                                           @click="setPropositionOfUser(proposition)"
                                           :disabled="proposition.is_disabled" :class="proposition.color"
                                           block variant="outlined" class="font-weight-bold mb-4" color="primary"
                                    >
                                    <span :class="proposition.is_choose_by_user ? 'text-white' : ''">
                                        {{ proposition.label }}
                                    </span>
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col md="6" cols="12" class="pa-0"
                               :style="this.is_mobile ? '' : 'border-left: #005D72 1px solid'">
                            <v-card class="py-4 px-8" elevation="0">
                                <v-row class="text-center">
                                    <v-col cols="12">
                                    <span class="text-h5">
                                        C'est à {{ current_player.pseudo }} de jouer
                                    </span>

                                        <v-divider class="border-opacity-25 mb-4 mt-4" color="primary"/>

                                        <template v-if="player_has_good_answer === null">
                                            <p v-if="!proposition_choose">
                                                {{ $t('play_game_which_is_intruder.choose_your_answer') }}
                                            </p>
                                            <p v-else>
                                                {{ $t('play_game_which_is_intruder.validate_your_answer') }}
                                            </p>
                                        </template>

                                        <template v-else>
                                            <v-chip color="green" size="large" v-if="player_has_good_answer">
                                                {{ $t('play_game_which_is_intruder.good_answer') }}
                                            </v-chip>
                                            <v-chip color="red" size="large" v-if="!player_has_good_answer">
                                                {{ $t('play_game_which_is_intruder.wrong_answer') }}
                                            </v-chip>
                                        </template>

                                        <v-btn
                                            v-if="player_has_good_answer !== false && can_go_to_next_player === false"
                                            @click="validateAnswer()" :disabled="!proposition_choose" class="mt-4"
                                            color="primary" block
                                        >
                                            {{ $t('play_game_which_is_intruder.validate') }}
                                        </v-btn>

                                        <v-btn v-if="can_go_to_next_player === true" @click="nextPlayer()"
                                               :disabled="!proposition_choose" :style="style_transformation_scale"
                                               class="mt-4" color="primary" block
                                        >
                                            {{ $t('play_game_which_is_intruder.next_player') }}
                                        </v-btn>

                                        <v-btn v-if="player_has_good_answer === false" @click="nextQuestion()"
                                               :disabled="!proposition_choose" :style="style_transformation_scale"
                                               class="mt-4" color="primary" block
                                        >
                                            {{ $t('play_game_which_is_intruder.next_question') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col md="6" cols="12" v-for="player in list_players_with_life"
                                           :key="player.pseudo">
                                        <v-card :color="getColorCardPlayer(player.total_life)" class="text-center">
                                            <v-card-title class="pt-6 pb-0">
                                                <v-chip :color="player.color" class="mr-2 mt-2" size="large"
                                                        variant="elevated" flat label block
                                                >
                                                    {{ player.pseudo }}
                                                </v-chip>
                                            </v-card-title>

                                            <v-card-text class="py-6">
                                                <template v-for="(index) in player.total_life" :key="index">
                                                    <v-icon color="red" size="large">mdi-cards-heart</v-icon>
                                                </template>

                                                <template v-for="(index) in 3 - player.total_life " :key="index">
                                                    <v-icon color="black" size="large">mdi-heart-broken</v-icon>
                                                </template>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>

                        <v-overlay v-model="all_answers_are_good" class="align-center justify-center" contained>
                            <v-container class="bg-white">
                                <p class="text-h5">
                                    {{ $t('play_game_which_is_intruder.well_play_all_answers_are_good') }}
                                </p>

                                <v-btn @click="nextQuestion()" class="mt-4" color="primary" block>
                                    {{ $t('play_game_which_is_intruder.next_question') }}
                                </v-btn>
                            </v-container>
                        </v-overlay>

                        <v-overlay v-model="is_end" class="align-center justify-center" contained>
                            <v-container class="bg-white">
                                <p class="text-h5">
                                    {{ $t('play_game_which_is_intruder.well_play_pseudo_win', {pseudo: current_player.pseudo}) }}
                                </p>

                                <v-btn @click="resetGame()" class="mt-4 mb-4" color="primary" block>
                                    {{ $t('play_game_which_is_intruder.try_again') }}
                                </v-btn>

                                <v-btn @click="this.$router.push({name: 'create_' + game_mode.reference})" block
                                       color="primary" variant="outlined"
                                >
                                    {{ $t('play_game_which_is_intruder.return') }}
                                </v-btn>
                            </v-container>
                        </v-overlay>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import QuestionRepository from "../../../services/QuestionRepository";

export default {
    name: "PlayGameWhichIsIntruder",
    data() {
        return {
            game_mode: this.$store.getters['game_mode/game_mode'],
            index_current_player: 0,
            current_player: null,
            list_players_with_life: this.$store.getters['player/players'],
            total_player_without_life: 0,
            is_end: false,

            loading_questions: true,
            questions: [],
            index_current_question: 0,
            current_question: {},
            wrong_answer: {},
            all_answers_are_good: false,
            total_answers_of_user: 0,

            proposition_choose: null,
            player_has_good_answer: null,
            can_go_to_next_player: false,

            style_transformation_scale: '',
        }
    },
    watch: {
        total_player_without_life() {
            this.is_end = this.total_player_without_life === this.list_players_with_life.length - 1;
            if (this.is_end) {
                this.addTraceabilityAuthenticate(false, true);
                this.nextPlayer();
                this.increaseTotalSeenOfQuestionRead(this.current_question.id);
            }
        },
        total_answers_of_user() {
            this.all_answers_are_good = this.total_answers_of_user === this.current_question.propositions.length - 1;
        }
    },
    created() {
        this.startGame();
    },
    methods: {
        startGame() {
            this.randomizeArray();
            this.current_player = this.list_players_with_life[0];
            this.getGameWhichIsIntruderQuestions();
        },

        randomizeArray() {
            for (let i = this.list_players_with_life.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [this.list_players_with_life[i], this.list_players_with_life[j]] = [this.list_players_with_life[j], this.list_players_with_life[i]];
            }
        },

        getGameWhichIsIntruderQuestions() {
            this.loading_questions = true;

            QuestionRepository.getQuestionsWithAnswerByGameModeId(this.game_mode.id, 50).then(response => {
                this.questions = response.data;
                this.current_question = this.questions[0];
                this.getWrongAnswer();
            }).catch(error => {
                this.manageError(error, this.$t('play_game_which_is_intruder.error_encountered_get_questions'));
            }).finally(() => {
                this.loading_questions = false;
            });
        },

        getWrongAnswer() {
            this.current_question.propositions.forEach(proposition => {
                if (proposition.is_answer) {
                    this.wrong_answer = proposition;
                }
            });
        },

        getColorCardPlayer(totalLife) {
            let color = '';
            switch (totalLife) {
                case 0:
                    color = 'blue-grey-lighten-2';
                    break;
                case 1:
                    color = 'red-lighten-4';
                    break;
                case 2:
                    color = 'orange-lighten-4';
                    break;
                case 3:
                    color = 'green-lighten-4';
                    break;
            }
            return color;
        },

        setPropositionOfUser(proposition) {
            if (this.player_has_good_answer === null) {
                if (this.proposition_choose) {
                    this.proposition_choose.is_choose_by_user = false;
                    this.proposition_choose.color = '';
                }

                this.proposition_choose = proposition;
                this.proposition_choose.is_choose_by_user = true;
                this.proposition_choose.color = 'bg-amber-darken-1 border-0';
            } else {
                this.style_transformation_scale = 'transform: scale(1.15);'
                setTimeout(() => {
                    this.style_transformation_scale = 'transform: scale(1);'
                }, 200);
            }
        },

        validateAnswer() {
            if (this.proposition_choose.id === this.wrong_answer.id) {
                this.current_player.total_life--;
                if (this.current_player.total_life === 0) {
                    this.total_player_without_life++;
                }


                this.current_question.propositions.forEach(proposition => {
                    if (proposition.id === this.proposition_choose.id) {
                        proposition.color = 'bg-red-darken-1 border-0';
                    }
                });
                this.player_has_good_answer = false;
                this.total_answers_of_user = 0;

            } else {
                this.current_question.propositions.forEach(proposition => {
                    if (proposition.id === this.proposition_choose.id) {
                        proposition.color = 'bg-green-darken-1 border-0';
                    }
                });
                this.player_has_good_answer = true;
                this.can_go_to_next_player = true;
                this.total_answers_of_user++;
            }
        },

        nextPlayer() {
            this.current_question.propositions.forEach(proposition => {
                if (proposition.id === this.proposition_choose.id) {
                    proposition.is_disabled = true;
                }
            });

            this.manageLife();

            this.proposition_choose = null;
            this.player_has_good_answer = null;
            this.can_go_to_next_player = false;
        },

        manageLife() {
            if (this.index_current_player + 1 === this.list_players_with_life.length) {
                this.index_current_player = 0
            } else {
                this.index_current_player++;
            }
            this.current_player = this.list_players_with_life[this.index_current_player];

            if (this.current_player.total_life === 0) {
                this.manageLife();
            }
        },

        nextQuestion() {
            this.total_answers_of_user = 0;
            this.proposition_choose = null;
            this.player_has_good_answer = null;

            this.index_current_question++;
            this.manageLife();
            this.increaseTotalSeenOfQuestionRead(this.current_question.id);
            this.current_question = this.questions[this.index_current_question];
            this.getWrongAnswer();
        },

        async increaseTotalSeenOfQuestionRead(idQuestion) {
            await QuestionRepository.patchIncreaseTotalSeen([idQuestion]);
        },

        resetGame() {
            this.loading_questions = true;
            this.index_current_player = 0;
            this.total_player_without_life = 0;
            this.index_current_question = 0;
            this.total_answers_of_user = 0;
            this.total_answers_of_user = 0;
            this.player_has_good_answer = null;

            this.list_players_with_life.forEach(player => {
                player.total_life = 3
            });

            this.startGame();
        }
    },
    mounted() {
        this.addTraceabilityAuthenticate(true, false);
    }
}
</script>