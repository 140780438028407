<template>
    <div id="login-view" class="h-100">
        <v-row class="h-100 ma-0" justify="center" align="center">
            <v-col class="d-flex justify-center">
                <v-form ref="form" :class="this.is_mobile ? 'w-100' : ''">
                    <v-card elevation="4" :class="this.is_mobile ? '' : 'width-500'"
                            class="d-flex flex-column justify-center py-4"
                    >
                        <div>
                            <v-card-title class="text-center pb-8">
                                <strong>{{ $t('login_view.welcome') }}</strong>
                            </v-card-title>

                            <v-card-text class="pb-0">
                                <v-text-field :label="$t('login_view.email')" v-model="email" :rules="email_rules"/>

                                <v-text-field :label="$t('login_view.password')" v-model="password"
                                              @keyup.enter="login()" :rules="password_rules"
                                              :type="show_password ? 'text' : 'password'"
                                              :append-inner-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                              @click:appendInner="show_password = !show_password"
                                              class="pt-2"
                                />
                            </v-card-text>

                            <v-card-actions class="mx-2 mb-4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn :loading="loading_login" @click="login()" block variant="flat"
                                               class="font-weight-bold" size="large" color="primary"
                                        >
                                            {{ $t('login_view.login') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" class="d-flex justify-center pb-0">
                                        <span @click="$router.push({name: 'password_reset'})"
                                              class="pointer text-caption text-decoration-underline text-primary"
                                        >
                                            {{ $t('login_view.password_lost') }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "LoginView",
    data() {
        return {
            email: '',
            email_rules: [
                v => !!v || this.$t('login_view.email_required'),
                v => (/^\S+@\S+\.\S+$/).test(v) || this.$t('login_view.wrong_email_format'),
            ],
            password: '',
            password_rules: [
                v => !!v || this.$t('login_view.password_required')
            ],
            show_password: false,
            loading_login: false
        }
    },
    methods: {
        async login() {
            const {valid} = await this.$refs.form.validate()

            if (valid) {
                this.loading_login = true;

                this.$auth.login({
                    data: {
                        email: this.email,
                        password: this.password
                    },
                    fetchUser: false
                }).then(response => {
                    this.$store.dispatch('user_connected/setAuthenticateUser', response.data);
                    this.$router.push({name: 'dashboard'});
                }).catch(error => {
                    this.manageError(error);
                }).finally(() => {
                    this.loading_login = false;
                });
            }
        }
    }
}
</script>
