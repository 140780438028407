import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import game from "@/store/modules/game";
import game_mode from "@/store/modules/game_mode";
import player from "@/store/modules/player";
import snackbar from '../store/modules/snackbar.ts';
import user_connected from '../store/modules/user_connected.ts';

export default createStore({
    state: {},
    mutations: {},
    modules: {game, game_mode, player, snackbar, user_connected},
    plugins: [createPersistedState()]
});
