import TraceabilityRepository from "@/services/TraceabilityRepository";

export default {
    data: () => ({
        is_online: navigator.onLine,
        language: navigator.language
    }),
    computed: {
        is_mobile() {
            return this.$vuetify.display.mobile;
        },
    },
    watch: {
        is_online() {
            if (!this.is_online) {
                this.showSnackbar('error', this.$t('error_message.no_internet'));
            }
        }
    },
    methods: {
        showSnackbar(type, text) {
            this.$store.dispatch('snackbar/showSnackbar', {snackbar_type: type, snackbar_text: text});
        },

        manageError(error, text) {
            if (!text) {
                text = this.$t('error_message.error_occurred')
            }

            if (!this.is_online) {
                this.showSnackbar('error', this.$t('error_message.no_internet'));
            } else if (error.response === undefined) {
                this.showSnackbar('error', text);
            } else {
                if (error.response.status === 401) {
                    this.showSnackbar('warning', this.$t('error_message.wrong_credentials'));
                } else if (error.response.status === 403) {
                    this.showSnackbar('error', this.$t('error_message.authorization_required'));
                    this.$auth.logout({});
                } else {
                    this.showSnackbar('error', text)
                }
            }
        },

        displayElement(arrayAccess) {
            let displayElement = false;
            if (arrayAccess.includes(this.$store.getters['user_connected/user_connected'].access_level)) {
                displayElement = true;
            }
            return displayElement;
        },

        getTruncateText(text, maxSize) {
            if(text) {
                text = text.length < maxSize ? text : text.substring(0, maxSize) + '...';
            }
            return text;
        },

        copy(value) {
            navigator.clipboard.writeText(value).then(() => {
               this.showSnackbar('info', this.$t('dashboard_view.copy_text'))
            });
        },

        addTraceabilityAuthenticate(isLaunch, isFinish) {
            TraceabilityRepository.postTraceabilityAuthenticate(this.game_mode.id, isLaunch, isFinish);
        }
    },
    mounted() {
        window.addEventListener('online', () => {
            this.is_online = navigator.onLine;
        });
        window.addEventListener('offline', () => {
            this.is_online = navigator.onLine;
        });
    }
}
